import {
    Accordion,
    Checkbox,
    Box,
    Text,
    HStack,
    Icon,
    IconButton,
    Flex,
    Heading,
    CircularProgress,
    useDisclosure,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuOptionGroup,
    MenuItemOption,
    Tooltip,
} from "@chakra-ui/react";
import { RepeatIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { MdFilterList } from "react-icons/md";
import { useEffect, useState, useRef } from "react";
import { getGroupedData, timestampSort } from "../../utils/Utility";
import CustomAccordionItem from "./CustomAccordion";
import { fetchData } from "../../utils/Utility";
import AlertInfoModal from "./AlertInfo";

export default function Data({
    data,
    setData,
    lastUpdated,
    setLastUpdated,
    query,
    timeRange,
    selectedRows,
    setSelectedRows,
    liveMode,
    isLoading,
    setIsLoading,
    selectedFilters, 
    setSelectedFilters
}) {
    const [groupedData, setGroupedData] = useState({}); // State to group the results
    const [links, setLinks] = useState({}); // State to set the links mapping to the app:errorcode
    const [updateTime, setUpdateTime] = useState("");
    const [infoRowData, setInfoRowData] = useState(null);
    const { onOpen, isOpen, onClose } = useDisclosure();

    

    const handleViewInfo = (e, rowId) => {
        e.stopPropagation();
        const rowData = data.find((row) => row.id === rowId);
        console.log(rowData);
        setInfoRowData(rowData);
        onOpen();
    };
    const handleCheckboxChange = (e, rowId) => {
        if (e.target.checked) {
            setSelectedRows((prev) => [...prev, rowId]);
        } else {
            setSelectedRows((prev) => prev.filter((selectedRowId) => selectedRowId !== rowId));
        }
    };

    const handleFilterChange = (value) => {
        setSelectedFilters(value);
    };
    useEffect(()=>{
        fetchData(query, timeRange, selectedFilters, setData, setIsLoading);
    }, [selectedFilters]);
    //-------------------------------------------------------------------------------------------------------------------------------------------------

    //------Group the data-----------------------------------------------------------------------------------------------------------------------------
    //----------------New record blink animation-----------------------------------------------------------------------------------------------
    const prevGroupedDataRef = useRef();
    const [newRecords, setNewRecords] = useState(new Set());
    useEffect(() => {
        prevGroupedDataRef.current = groupedData;
    }, [groupedData]);

    useEffect(() => {
        if (liveMode) {
            const timeout = setTimeout(() => {
                setNewRecords(new Set());
            }, 5000); // Adjust delay to match blink animation duration

            return () => clearTimeout(timeout); // Clean up on unmount
        }
    }, [newRecords, liveMode]);

    useEffect(() => {
        if (data.length != 0) {
            const { groupedData, links } = getGroupedData(data);
            setLinks(links);
            if (liveMode) {
                const newItems = [];
                Object.keys(groupedData).forEach((app) => {
                    Object.keys(groupedData[app]).forEach((code) => {
                        Object.keys(groupedData[app][code]).forEach((library) => {
                            (groupedData[app][code][library] || []).forEach((record) => {
                                const prevRecords = prevGroupedDataRef.current[app]?.[code]?.[library] || [];
                                if (
                                    !prevRecords.some(
                                        (prevRecord) =>
                                            prevRecord.alertId === record.alertId &&
                                            prevRecord.alerted === record.alerted &&
                                            prevRecord.host === record.host &&
                                            prevRecord.id === record.id &&
                                            prevRecord.level === record.level &&
                                            prevRecord.originalTimestamp === record.originalTimestamp &&
                                            prevRecord.app === record.app &&
                                            prevRecord.library === record.library
                                    )
                                ) {
                                    newItems.push(`${app}-${code}-${library}`);
                                }
                            });
                        });
                    });
                });
                setNewRecords(new Set(newItems));
            }
            setGroupedData(groupedData);
        }
    }, [data]);
    //--------------------------------------------------------------------------------------------------------------------------------------------------

    //----------------Set last updated time and Refresh action------------------------------------------------------------------------------------------
    useEffect(() => {
        const date = new Date(lastUpdated);
        const options = {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false,
        };
        const dateString = date.toLocaleString("en-US", options);
        setUpdateTime(dateString);
    }, [lastUpdated]);

    const handleRefresh = () => {
        const timestamp = new Date().getTime();
        fetchData(query, timeRange, selectedFilters, setData, setIsLoading);
        setLastUpdated(timestamp);
    };
    //-----------------------------------------------------------------------------------------------------------------------------------------------------

    return (
        <Flex direction={"column"} padding={"0 70px"}>
            <Box
                position="relative"
                paddingTop={"1vh"}
                paddingBottom={"1vh"}
                borderStyle={"solid"}
                borderColor={"black"}
                borderWidth={"1px"}
                paddingLeft={"1vw"}
            >
                <HStack justifyContent="space-between">
                    <HStack gap={"5vw"}>
                        <HStack>
                            <Tooltip label='Library names are in bold' placement="top">
                                <Text width={"4vw"} fontSize={"small"}>
                                    App / Lib
                                </Text>
                            </Tooltip>
                            <Menu closeOnSelect={false}>
                                <MenuButton as={IconButton} icon={<Icon as={MdFilterList} />} size="xs" />
                                <MenuList>
                                    <MenuOptionGroup
                                        defaultValue={selectedFilters}
                                        title="Filters"
                                        type="checkbox"
                                        onChange={handleFilterChange}
                                    >
                                        <MenuItemOption value="App">App</MenuItemOption>
                                        <MenuItemOption value="Library">Library</MenuItemOption>
                                    </MenuOptionGroup>
                                </MenuList>
                            </Menu>
                        </HStack>

                        <Text width={"6vw"} fontSize={"small"}>
                            <Tooltip label="Defined Error Code from the Application" placement="top">
                                Error Code
                            </Tooltip>
                        </Text>

                        <Text width={"10vw"} fontSize={"small"}>
                            <Tooltip label="Link to the Runbook" placement="top">
                                Runbook
                            </Tooltip>
                        </Text>

                        <Text width={"10vw"} fontSize={"small"}>
                            <Tooltip label="Link to the self-heal script" placement="top">
                                Selfheal
                            </Tooltip>
                        </Text>
                        <Text width={"11vw"} fontSize={"small"}>
                            Latest Record Timestamp
                        </Text>
                    </HStack>
                    <HStack alignItems={"center"} paddingRight={5}>
                        <Text fontSize={"xs"} maxWidth={"14vw"}>
                            Last Updated : {updateTime}
                        </Text>
                        <IconButton
                            colorScheme="green"
                            variant="solid"
                            size={"xs"}
                            isRound={true}
                            onClick={handleRefresh}
                            icon={<RepeatIcon />}
                        />
                    </HStack>
                </HStack>
            </Box>
            {isLoading && (
                <Flex justifyContent={"center"} p={10}>
                    <CircularProgress isIndeterminate color="blue.300" />
                </Flex>
            )}
            {!isLoading && (
                <Accordion allowToggle paddingBottom={"5vw"}>
                    {data.length == 0 ? (
                        <Flex justifyContent="center">
                            <Box padding={5}>
                                <Heading size={"md"}>No Data Available</Heading>
                            </Box>
                        </Flex>
                    ) : (
                        Object.entries(groupedData)
                            .flatMap(([appName, codes]) =>
                                Object.entries(codes).flatMap(([code, libraries]) =>
                                    Object.entries(libraries).map(([library, values]) => ({
                                        appName,
                                        code,
                                        library,
                                        values,
                                    }))
                                )
                            )
                            .sort((a, b) => {
                                const maxTimestampA = Math.max(
                                    ...a.values.map(({ originalTimestamp }) => originalTimestamp)
                                );
                                const maxTimestampB = Math.max(
                                    ...b.values.map(({ originalTimestamp }) => originalTimestamp)
                                );
                                return maxTimestampB - maxTimestampA;
                            })
                            .map(({ appName, code, library, values }, index) => {
                                const resolvedCount = values.filter(({ resolved }) => resolved).length;
                                const unresolvedCount = values.length - resolvedCount;
                                const maxTimestamp = Math.max(
                                    ...values.map(({ originalTimestamp }) => originalTimestamp)
                                );
                                return (
                                    <CustomAccordionItem
                                        key={`${appName}-${code}`}
                                        app={appName}
                                        code={code}
                                        library={library}
                                        values={values}
                                        links={links}
                                        selectedRows={selectedRows}
                                        handleViewInfo={handleViewInfo}
                                        handleCheckboxChange={handleCheckboxChange}
                                        resolvedCount={resolvedCount}
                                        unresolvedCount={unresolvedCount}
                                        maxTimestamp={maxTimestamp}
                                        newRecords={newRecords}
                                    />
                                );
                            })
                    )}
                </Accordion>
            )}
            <AlertInfoModal isOpen={isOpen} onClose={onClose} rowData={infoRowData} />
        </Flex>
    );
}
