import { VStack, Input, Text, IconButton, HStack, Tooltip, Container, Select, Flex, Spacer, ButtonGroup } from '@chakra-ui/react'
import { QuestionOutlineIcon, SearchIcon } from '@chakra-ui/icons';
import { fetchData } from '../../utils/Utility';
import { TiMediaPlayOutline , TiMediaPauseOutline } from "react-icons/ti";
import '../../styles/App.css';

export default function Filters({setData,setLastUpdated, query, setQuery, timeRange, setTimeRange, liveMode, setLiveMode, isLoading, setIsLoading, setSelectedRows, selectedFilters}){

    const handleFormSubmit = (e)=>{
        e.preventDefault();
        const currentTime = new Date().getTime();
        setLastUpdated(currentTime);
        setSelectedRows([]);
        fetchData(query, timeRange, selectedFilters, setData, setIsLoading);
    }

    const toolTipInfo = "Input keywords related to the App or Library Name, ErrorCode, AlertId";

    return (
        <Flex padding={"0 70px"}>
            <form onSubmit={(e) => handleFormSubmit(e)} className='filter-form'>
                <Flex className="filter-flex-box" gap={"10px"} width={"100%"} grow={1} shrink={0}>
                    <Flex direction={"column"} grow={2} shrink={0} alignItems={"left"} gap={"5px"}>
                        <HStack gap={"1px"}>
                            <Text width={"fit-content"} padding={"0px 5px"}>
                                Search Query
                            </Text>
                            <Tooltip label={toolTipInfo} placement="right">
                                <QuestionOutlineIcon />
                            </Tooltip>
                        </HStack>
                        <Input
                            placeholder="Enter Search Keywords"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            isDisabled={isLoading}
                        />
                    </Flex>

                    <Flex direction={"row"} grow={1} shrink={0} alignItems={"center"} justifyContent={"center"} gap={"5px"}>
                        <Flex direction={"column"} grow={1} shrink={0} alignItems={"left"} gap={"5px"}>
                            <Text width={"fit-content"}>Time Range</Text>
                            <Select
                                placeholder="Select time range"
                                value={timeRange}
                                onChange={(e) => setTimeRange(e.target.value)}
                                isDisabled={isLoading}
                            >
                                <option value="min5">Last 5 mins</option>
                                <option value="min15">Last 15 mins</option>
                                <option value="min30">Last 30 mins</option>
                                <option value="hour1">Last 1 hour</option>
                                <option value="hour24">Last 24 hours</option>
                                <option value="day7">Last 7 days</option>
                                <option value="day30">Last 30 days</option>
                            </Select>
                        </Flex>
                        <ButtonGroup>
                            <Tooltip label="Search" placement="top">
                                <IconButton
                                    marginTop={7}
                                    type="submit"
                                    aria-label="Search database"
                                    size={"md"}
                                    variant={"solid"}
                                    fontSize="20px"
                                    colorScheme="blue"
                                    isDisabled={isLoading}
                                    icon={<SearchIcon />}
                                />
                            </Tooltip>
                            <Tooltip label="Live Mode" placement="top-end">
                                <IconButton
                                    icon={liveMode ? <TiMediaPauseOutline /> : <TiMediaPlayOutline />}
                                    marginTop={7}
                                    size={"md"}
                                    fontSize="30px"
                                    onClick={() => {
                                        setLiveMode(!liveMode);
                                    }}
                                    isDisabled={isLoading}
                                    colorScheme={liveMode ? "red" : "green"}
                                    className={liveMode ? "blinking" : ""}
                                />
                            </Tooltip>
                        </ButtonGroup>
                    </Flex>
                </Flex>
            </form>
        </Flex>
    );
}