import { AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Text, HStack, Link, Button, IconButton, Checkbox } from "@chakra-ui/react";
import Table from './Table';
import { WarningIcon, CheckCircleIcon, InfoIcon} from '@chakra-ui/icons';
import { timestampSort } from "../../utils/Utility";
import '../../styles/App.css';

const CustomAccordionItem = ({app, library, code, values, links, selectedRows, handleViewInfo, handleCheckboxChange, resolvedCount, unresolvedCount, maxTimestamp, newRecords}) => {
    const blink = newRecords.has(`${app}-${code}`);
    console.log("Accordian ", library, app);

    //--------------------Define data columns and their styles and actions----------------------------------------------------------------------------
    const columnValue = [
        { id: "alertid", name: "AlertId", selector: (row) => row.alertId, sortable: false, width: "15vw" },
        {
            id: "timestamp",
            name: "Timestamp",
            selector: (row) => row.timestamp,
            sortable: true,
            sortFunction: timestampSort,
            width: "15vw",
        },
        { id: "host", name: "Host", selector: (row) => row.host, sortable: true },
        { id: "level", name: "Level", selector: (row) => row.level, sortable: false },
        {
            id: "appUse", name: "Application Use", selector: (row) => row.app, sortable: false
        },
        {
            id: "info",
            name: "Alert Info",
            cell: (row) => (
                <IconButton
                    icon={<InfoIcon color="black" />}
                    borderRadius={60}
                    size={"sm"}
                    variant="outline"
                    onClick={(e) => handleViewInfo(e, row.id)}
                />
            ),
        },
        { id: "alerted", name: "Alerted", selector: (row) => row.alerted, sortable: false },
        {
            id: "resolved",
            name: "Select to Resolve",
            cell: (row) =>
                !row.resolved && (
                    <Checkbox
                        borderColor={"black"}
                        onChange={(e) => handleCheckboxChange(e, row.id)}
                        isChecked={selectedRows.includes(row.id)}
                    />
                ),
        }
    ];
    const filteredColumnValue = columnValue.filter(column => {
        if(column.id === "appUse"){
            return (library!=="null")
        } return true;
    });

    const conditionalRowStyles = [
        {
            when: (row) => row.resolved,
            style: {
                backgroundColor: "rgba(212, 237, 218, 0.3)",
            },
        },
        {
            when: (row) => !row.resolved,
            style: {
                backgroundColor: "rgba(248, 215, 218, 0.3)",
            },
        },
    ];

    return (
        <AccordionItem 
            key={`${app}-${code}`}
            className={blink? 'alert':''}>
            <h2>
                <AccordionButton>
                    <HStack  as="span" flex='1' textAlign='left' paddingTop={'1vh'} paddingBottom={'1vh'} gap={'5vw'}>
                        <Text width={'6vw'} style={{fontWeight: library==="null"?"200":"800"}}>{app}</Text>
                        <Text width={'6vw'}>{code}</Text>
                        <Link href={links[app][code][library][0].runbook} isExternal>
                            <Text 
                                width={'10vw'} noOfLines={1} 
                                as={(links[app][code][library][0].runbook==null)?'i':'u'}
                                color={(links[app][code][library][0].runbook==null)?'grey':'blue'}>
                                {(links[app][code][library][0].runbook==null)?"Not Available":links[app][code][library][0].runbook}
                            </Text>
                        </Link>
                        <Link href={links[app][code][library][0].selfheal} isExternal>
                            <Text 
                            width={'10vw'} noOfLines={1} 
                            as={(links[app][code][library][0].selfheal==null)?'i':'u'}
                            color={(links[app][code][library][0].selfheal==null)?'grey':'blue'}>
                                {(links[app][code][library][0].selfheal==null)?"Not Available":links[app][code][library][0].selfheal}
                            </Text>
                        </Link>
                        <Text width={'14vw'}>{new Date(maxTimestamp).toLocaleString()}</Text>
                    </HStack>
                    <HStack>
                        <HStack 
                        backgroundColor={'lightgreen'} 
                        padding={1}
                        borderRadius={5}
                        >
                            <Text>{resolvedCount}</Text>
                            <CheckCircleIcon color={'darkgreen'}/>
                        </HStack>
                        <HStack
                        backgroundColor={'lightpink'} 
                        padding={1}
                        borderRadius={5}
                        >
                            <Text>{unresolvedCount}</Text>
                            <WarningIcon color={'red'}/>
                        </HStack>
                        </HStack>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel>
                <Table
                    key={`${app}_${code}`}
                    columns={filteredColumnValue}
                    data={values}
                    conditionalRowStyles={conditionalRowStyles}
                />
            </AccordionPanel>
        </AccordionItem>
    );
}

export default CustomAccordionItem;